'use client';

import Link from 'next/link';
import React from 'react';
import { classNames } from '#/packages/ui';
import wavesSvg from '#/src/assets/illustrations/waves.svg';
import { LogoImg } from '#/src/components/Page/Logo/LogoImg';
import { LoginPageQuote } from '../assets/LoginPageQuote';
export interface AuthenticationLayoutProps {
  id?: string;
  from?: string;
  header?: React.ReactNode;
  isModal?: boolean;
  withTerms?: boolean;
  withIllustrations?: boolean;
  children: React.ReactNode;
}

export default function AuthenticationLayout(props: AuthenticationLayoutProps) {
  const { isModal, withTerms, withIllustrations = false } = props;

  return (
    <div
      id={props.id}
      className={classNames(
        'relative flex flex-col min-h-[100vh] sm:pt-10 pb-4',
        'bg-secondary-100',
        isModal ? 'h-screen overflow-hidden' : '',
        'text-black dark:text-gray-100'
      )}
    >
      <WavesBackground />
      <div
        className={classNames(
          'relative z-20 flex flex-1 flex-col w-full h-full lg:flex-row sm:h-auto sm:justify-center sm:items-center mx-auto max-w-[1500px] sm:px-6 lg:px-8 lg:gap-4',
          'min-h-full'
        )}
      >
        {withIllustrations && (
          <div className='relative hidden lg:flex sm:w-1/2 items-center justify-center'>
            <div className='flex-col justify-center items-start space-y-8 m-12'>
              <LogoImg width={40} />
              <LoginPageQuote className='w-[489px]' />
            </div>
          </div>
        )}
        <div className='w-full sm:w-1/2'>
          <div
            className={classNames(
              'max-sm:flex max-sm:flex-col max-sm:flex-1 z-20 relative backdrop-blur-lg sm:mx-auto sm:w-full sm:max-w-[480px] sm:rounded-xl',
              'transition-all duration-1000',
              isModal ? 'max-sm:h-full' : '',
              'bg-white',
              'sm:shadow-[0_0_118px_0_rgba(0,0,0,0.1)]'
            )}
          >
            <div
              className={classNames(
                'max-sm:flex max-sm:flex-col max-sm:flex-1 max-sm:h-full px-6 py-8 sm:px-8'
              )}
            >
              <div className={'mb-4'}>
                {props.header ? (
                  <h2 className='text-center text-gray-700 text-xl font-normal leading-9 tracking-wide dark:text-gray-100'>
                    {props.header}
                  </h2>
                ) : null}
              </div>
              {props.children}
            </div>
          </div>

          {withTerms ? <Terms /> : null}
        </div>
      </div>
    </div>
  );
}

const Terms = () => (
  <p className={'text-center text-xs text-gray-400 mt-4 mb-4'}>
    By continuing, you agree to our{' '}
    <Link
      target={'_blank'}
      href={'https://www.superads.ai/terms-of-use'}
      className={'text-gray-500 whitespace-nowrap'}
    >
      Terms of Use
    </Link>{' '}
    and{' '}
    <Link
      target={'_blank'}
      href={'https://www.superads.ai/privacy'}
      className={'text-gray-500 whitespace-nowrap'}
    >
      Privacy Policy
    </Link>
    .
  </p>
);

const WavesBackground = () => (
  <div
    className={`absolute inset-0 max-sm:hidden bg-secondary-200`}
    style={{
      WebkitMaskImage: `url(${wavesSvg.src})`,
      maskImage: `url(${wavesSvg.src})`,
      WebkitMaskSize: 'contain',
      maskSize: 'contain',
      WebkitMaskPosition: 'start',
      maskPosition: 'start',
      WebkitMaskRepeat: 'no-repeat',
      maskRepeat: 'no-repeat'
    }}
  />
);
